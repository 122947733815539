<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        ListProvinceProps: {
            type: Array,
            default: () => [],
        },
        tabActiveProps: {
            type: Number,
            default: () => 1,
        },
    },
    data() {
        return {
            page: 1,
            listPage: 3,
            listHeader: [
                {
                    id: 1,
                    name: 'TP. Hồ Chí Minh',
                },
                {
                    id: 2,
                    name: 'Quy Nhơn',
                },
                {
                    id: 3,
                    name: 'Quảng Ngãi',
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            ListProject: 'project/ListProject',
            TypicalProduct: 'project/TypicalProduct',
        }),
        ListHeader() {
            const arr = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const key in this.TypicalProduct) {
                if (Object.hasOwnProperty.call(key)) {
                    const element = key;
                    console.log(element);
                }
            }
            return arr;
        },
    },
    watch: {
        TypicalProduct: {
            handler(newVal) {
                if (newVal) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const key in newVal) {
                        if (Object.hasOwnProperty.call(newVal, key)) {
                            const element = newVal[key];
                            console.log(element);
                        }
                    }
                }
            },
        },
        tabActiveProps: {
            handler(newVal) {
                if (newVal) {
                    this.$emit('changetabActiveProps', newVal);
                    this.$store.dispatch('project/get_listProject', {
                        typical: 1,
                        province: newVal,
                        project_service: 1,
                    });
                    this.$store.dispatch(
                        'project/get_typicalProduct',
                        this.tabActiveProps,
                    );
                }
            },
        },
        page: {
            handler(newVal) {
                if (newVal) {
                    this.$store.dispatch('project/get_listProject', {
                        typical: 1,
                        province: this.tabActiveProps,
                        project_service: 1,
                        page: newVal,
                    });
                }
            },
        },
    },
    created() {},
};
</script>
<template>
    <b-container class="invest-list">
        <div class="category">
            <h3>{{ $t('entertainmentService') }}</h3>
            <div class="tab-heading">
                <div
                    class="tab-item"
                    v-for="province in ListProvinceProps"
                    :key="`header-project-${province.category_id}`"
                    :class="
                        tabActiveProps == province.category_id
                            ? 'tab-active'
                            : ''
                    "
                    @click="tabActiveProps = province.category_id"
                >
                    {{ province.category_name }}
                </div>
            </div>
            <b-row class="category-content" v-if="TypicalProduct.length > 0">
                <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="project in TypicalProduct"
                    :key="`project-${project.product_id}`"
                >
                    <div class="project-item">
                        <div class="project-img">
                            <img :src="project.product_image" alt="" />
                            <div class="content-project" v-if="false">
                                <h3>{{ project.product_name }}</h3>
                                <p v-html="project.product_content"></p>
                            </div>
                        </div>
                        <div class="project-name">
                            <p>{{ project.product_name }}</p>
                            <span> {{ project.product_address }} </span>
                        </div>

                        <div class="project-line"></div>
                    </div>
                </b-col>
                <b-col cols="12" v-if="false">
                    <div class="pagination">
                        <div
                            v-for="indexPage in ListProject.list.last_page"
                            :key="indexPage"
                            class="dot"
                            :class="page == indexPage ? 'dot-active' : ''"
                            @click="page = indexPage"
                        ></div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="category-content" v-else>
                <b-col cols="12">{{ $t('temporarilyNoProjects') }}</b-col>
            </b-row>
        </div>
    </b-container>
</template>

<style lang="scss" scoped>
.invest-list {
    .category {
        width: 100%;
        padding: 50px 0px;
        text-align: center;
        @media (max-width: 575px) {
            padding: 30px 0px;
        }
        h3 {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 40px;
            color: #003189;
            @media (max-width: 575px) {
                font-size: 30px;
            }
        }
        .tab-heading {
            width: max-content;
            max-width: 100%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            box-shadow: 0px 0px 5px #0000005a;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin: 30px auto 0px;
            overflow-x: auto;
            @media (max-width: 991px) {
                justify-content: inherit;
            }
            @media (max-width: 575px) {
                height: 40px;
                max-width: 100%;
                width: 100%;
            }
            .tab-item {
                max-width: max-content;
                min-width: 150px;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #363795;
                font-weight: bold;
                padding: 0 15px;
                @media (max-width: 575px) {
                    max-width: unset;
                    // width: auto;
                    // height: auto;
                    font-size: 16px;
                    padding: 0 10px;
                    min-width: unset;
                    line-height: 110%;
                }
                @media (max-width: 575px) {
                    max-width: unset;
                    // width: auto;
                    // height: auto;
                    font-size: 14px;
                    line-height: 110%;
                    padding: 0 10px;
                    min-width: unset;
                }
                &.tab-active {
                    background-image: linear-gradient(
                        to right,
                        #363795,
                        #0040ff
                    );
                    color: #ffffff;
                }
                cursor: pointer;
                &:first-child {
                    border-top-left-radius: 20px;
                }
                &:last-child {
                    border-top-right-radius: 20px;
                }
            }
        }
        .category-content {
            padding: 30px 50px;
            box-shadow: 1px 1px 5px #0000005a;
            border-radius: 10px;
            @media (max-width: 991px) {
                padding: 20px;
            }
            @media (max-width: 575px) {
                padding: 20px 0px;
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px auto;
                width: 100%;
                display: block;
                max-width: 275px;
                min-height: 320px;
                position: relative;
                overflow: hidden;
                @media (max-width: 575px) {
                    max-width: 300px;
                }

                &:hover {
                    .content-project {
                        opacity: 1;
                        transition: all 1s;
                    }
                    img {
                        transform: scale(1.5);
                        transition: all 1s;
                    }
                }
                img {
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                }
                .content-project {
                    opacity: 0;
                    display: flex;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: #00000096;
                    overflow: hidden;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    z-index: 20;
                    border-radius: 10px;
                    h3 {
                        color: #ffff;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    p {
                        color: #ffff;
                        font-size: 14px;
                        text-align: center;
                        display: -webkit-box;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        margin-bottom: 0px;
                    }
                }

                .project-name {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    position: absolute;
                    bottom: 0;
                    z-index: 10;
                    flex-direction: column;
                    p {
                        text-align: center;
                        display: -webkit-box;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 0px;
                        color: #fff;
                        @media (max-width: 575px) {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                    span {
                        width: 100%;
                        color: #fff;
                        font-weight: 500;
                        display: -webkit-box;
                        line-height: 22px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                .project-line {
                    width: 60%;
                    height: 8px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-color: #fff;
                    border-radius: 10px;
                    position: absolute;
                    bottom: 1px;
                    box-shadow: 0 0px 30px 89px #363795;
                }
            }
        }
        .pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 30px;
            @media (max-width: 575px) {
                margin-top: 20px;
            }
            .dot {
                cursor: pointer;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #999999;
                margin: 0px 10px;
                &.dot-active {
                    background-color: #363795;
                }
            }
        }
    }
}
</style>
