<script>
import { mapGetters } from 'vuex';
import ListProject from './ListProject_re.vue';

export default {
    components: { ListProject },
    data() {
        return {
            key: '',
            tabActive: null,
        };
    },
    computed: {
        ...mapGetters({
            TypicalProductAddress: 'project/TypicalProductAddress',
            ListServiceRePresentatve: 'project/ListServiceRePresentatve',
            TypicalProduct: 'project/TypicalProduct',
        }),
        listRepresent() {
            const list = [
                {
                    id: 1,
                    img: 'bat-dong-san',
                    title: this.$t('phuQuocRealEstate'),
                    about: this.$t('leadingTouristDevelopment'),
                    process: this.$t('ongoing'),
                    address: '',
                    scale: '',
                },
                {
                    id: 2,
                    img: 'cong-nghe',
                    title: this.$t('blockchainTechnology'),
                    about: this.$t('advantagesOfBlockchain'),
                    process: '',
                    address: '',
                    scale: '',
                },
                {
                    id: 3,
                    img: 'chan-nuoi',
                    title: this.$t('hiTechFarming'),
                    about: this.$t('advantagesOfBlockchain'),
                    process: '',
                    address: 'Lâm Đồng, Phú Quốc',
                    scale: this.$t('scaleSize'),
                },
                {
                    id: 4,
                    img: 'tham-my-vien',
                    title: this.$t('beautyTechnology'),
                    about: this.$t('anEssentialHuman'),
                    process: '',
                    address: '',
                    scale: '',
                },
            ];
            return list;
        },
    },
    methods: {
        changeTabActiveInParent(tabActive) {
            this.tabActive = tabActive;
        },
        onSearchTextProject() {
            this.$store.dispatch('project/get_listProjectRepresentative', {
                key: this.key,
            });
        },
    },
    watch: {
        TypicalProductAddress: {
            handler(newVal) {
                if (newVal) {
                    this.tabActive = newVal[0].id;
                }
            },
        },
    },
    created() {
        this.$store.dispatch('project/get_typicalProductAddress');
        this.$store.dispatch('project/get_listProjectRepresentative');
    },
    mounted() {},
};
</script>
<template>
    <b-container fluid class="representative">
        <b-container class="banner">
            <img
                src="~@/assets/images/mock/project/invest.png"
                class="banner-img"
                alt=""
            />
            <div class="search-bar">
                <div class="title">{{ $t('typicalProducts') }}</div>
                <div class="input">
                    <b-button @click="onSearchTextProject">
                        <img src="~@/assets/images/icons/search.png" alt="" />
                    </b-button>
                    <b-form-input
                        :placeholder="$t('search')"
                        v-model="key"
                    ></b-form-input>
                </div>
            </div>
        </b-container>
        <ListProject
            :ListProvinceProps="TypicalProductAddress"
            :tabActiveProps="tabActive"
            @changetabActiveProps="changeTabActiveInParent"
        />
        <b-container
            fluid
            class="section-content"
            v-for="(represent, idx) in listRepresent"
            :key="`represent-${represent.id}`"
            :data-aos="idx % 2 === 0 ? 'fade-left' : 'fade-right'"
        >
            <b-container>
                <b-row class="row-full">
                    <b-col cols="12" md="7" lg="6">
                        <div class="title">
                            {{ represent.title }}
                        </div>
                        <div class="description">
                            <p v-if="represent.about">
                                <span>{{ $t('projectIntroduction') }}</span>
                                <span v-html="represent.about"></span>
                            </p>
                            <p v-if="represent.process">
                                <span> {{ $t('progress') }} </span>
                                {{ represent.process }}
                            </p>
                            <p v-if="represent.address">
                                <span>{{ $t('location') }}</span>
                                {{ represent.address }}
                            </p>
                            <p v-if="represent.scale">
                                <span> {{ $t('scale') }} </span>
                                {{ represent.scale }}
                            </p>
                        </div>
                    </b-col>
                    <b-col cols="12" md="5" lg="6">
                        <div class="project-item">
                            <div class="project-img">
                                <img
                                    :src="
                                        require(`../../assets/images/mock/project/${represent.img}.png`)
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="project-name"></div>
                            <div class="project-line"></div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <template v-if="false">
            <b-container fluid class="section-content" data-aos="fade-up">
                <h3 class="text-center font-weight-bold text-uppercase mb-0">
                    {{ $t('noTypicalProjects') }}
                </h3>
            </b-container>
        </template>
    </b-container>
</template>
<style lang="scss">
.representative {
    padding: 50px 0;

    .banner {
        position: relative;
        min-height: 300px;
        border-radius: 25px;
        @media (min-width: 1400px) {
            max-width: 1320px;
        }
        img.banner-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 25px;
        }
        .search-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: max-content;
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .title {
                font-family: 'Roboto', sans-serif;
                color: #fff;
                font-size: 40px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
            .input {
                border: 1px solid #000093;
                background: rgba($color: #ffffff, $alpha: 0.4);
                height: 45px;
                width: 100%;
                max-width: 420px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                button {
                    flex: 0 0 60px;
                    height: 45px;
                    padding: 5px;
                    background: transparent;
                    border: 0;
                    outline: none;
                    box-shadow: none;
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                input {
                    border: 0;
                    background: transparent;
                    box-shadow: none;
                    color: #fff;
                    font-weight: 500;
                    &::placeholder {
                        color: #fff;
                        opacity: 0.9;
                    }
                }
            }
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
    .section-content {
        padding: 20px 0;
        &:nth-child(odd) {
            background-color: #f7f7f7;
        }
        @media (min-width: 768px) {
            &:nth-child(even) {
                .row-full {
                    .col-md-7:first-child {
                        order: 2;
                        .title {
                            &:before {
                                right: auto;
                                left: 0;
                            }
                            &:after {
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                    .project-item {
                        margin: 20px auto 20px 0;
                    }
                }
            }
            &:nth-child(odd) {
                .row-full {
                    .project-item {
                        margin: 20px 0 20px auto;
                    }
                }
            }
        }
        .row-full {
            overflow: hidden;
            align-items: center;
            padding: 20px 0;
            .title-section {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 40px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 30px;
                }
            }
            .title {
                font-family: 'Roboto', sans-serif;
                color: #003189;
                font-size: 35px;
                font-weight: 600;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 25px;
                }
                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 0;
                    height: 4px;
                    width: calc(100% - 10px);
                    background: #003189;
                }
                // &:after {
                // }
                &:before {
                    right: 0;
                    left: auto;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    border: 3px solid #003189;
                    bottom: 0;
                }
            }
            .description {
                p {
                    font-weight: 400;
                    font-size: 18px;
                    span {
                        &:nth-child(1) {
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }
                }
            }
            .project-item {
                border-radius: 10px;
                box-shadow: 5px 5px 10px #00000058;
                margin: 20px auto;
                width: 100%;
                max-width: 375px;
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 414px;
                }
                .project-img {
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        .content-project {
                            opacity: 1;
                            transition: all 1s;
                        }
                    }
                    img {
                        border-radius: 10px;
                        width: 100%;
                        height: auto;
                    }
                    @media (max-width: 575px) {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 56.25%;
                        border-radius: 10px;
                        position: relative;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .content-project {
                        opacity: 0;
                        display: flex;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #00000076;
                        overflow: hidden;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        h3 {
                            color: #ffff;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        p {
                            color: #ffff;
                            font-size: 14px;
                            text-align: center;
                            display: -webkit-box;
                            line-height: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            margin-bottom: 0px;
                        }
                    }
                }
                .project-name {
                    width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    p {
                        text-align: center;
                        display: -webkit-box;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 0px;
                        @media (max-width: 575px) {
                            font-size: 16px;
                            line-height: 25px;
                        }
                    }
                }
                .project-line {
                    width: 100%;
                    height: 8px;
                    background-color: #363795;
                    border-radius: 10px;
                }
            }
        }
    }
    @media (max-width: 575px) {
        padding: 25px 10px;
        .banner {
            min-height: 250px;
        }
    }
}
</style>
